<template>
  <div class="main-content">
    <!-- title -->
    <div class="label">{{ label || placeholder }}:</div>
    <!-- value -->
    <div v-if="dicText">
      {{ dicText }}
    </div>
    <div v-else-if="isImg">
      <ImageUpload disabled v-model="value"></ImageUpload>
    </div>
    <div v-else-if="isFile">
      <ImageUpload disabled v-model="value" :type="'BXTK'"></ImageUpload>
    </div>
    <div v-else-if="isRadio">
      {{ value ? "是" : "否" }}
    </div>
    <div v-else-if="isRich">
      <!-- <div class="vue-quill-editor"> -->
      <quill-editor v-model="value" disabled />
      <!-- </div> -->
    </div>
    <div v-else>
      {{ value || placeholder }}
    </div>
  </div>
</template>

<script>
import ImageUpload from "@/components/ImageUpload";

export default {
  name: "FormItemText",
  model: {
    prop: "value",
    event: "change",
  },
  components: { ImageUpload },
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [Number, String, Object, Boolean],
      default: "",
    },
    placeholder: {
      type: String,
      default: "- - -",
    },
    dic: {
      type: Array,
      default: () => [],
    },
    isImg: {
      type: Boolean,
      default: false,
    },
    isFile: {
      type: Boolean,
      default: false,
    },
    isRadio: {
      type: Boolean,
      default: false,
    },
    isRich: {
      type: Boolean,
      default: false,
    },
  },
  watch: {},
  computed: {
    dicText() {
      if (this.dic.length) {
        let res = this.dic.find((item) => {
          return item.dictKey == this.value;
        });
        if (res) {
          return res.dictValue;
        }
      }
      return "";
    },
  },
  data() {
    return {
      //dicText: ''
      editorConfig: {
        modules: {
          toolbar: {
            container: [],
          },
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  margin-top: 24px;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  .label {
    margin-right: 14px;
  }
}
</style>
