<template>
  <div ref="mian"
    class="main">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: null
    }
  },
  methods: {
    verify(fun) {
      let varr = this.$slots.default;
      let empString = [];
      if (varr.length && this.model) {
        varr.forEach(item => {
          let prop = item.componentOptions.propsData.prop.split('.');
          let title = item.componentOptions.propsData.title;
          if (prop.length) {
            if (prop.length == 1) {
              if (this.model[prop[0]] instanceof Array) {
                if (!this.model[prop[0]].length) {
                  empString.includes(title) ? '' : empString.push(title);
                }
              } else {
                if (!this.model[prop[0]]) {
                  empString.includes(title) ? '' : empString.push(title);
                }
              }
            } else {
              let inda = this.model;
              prop.forEach(p => {
                inda = inda[p];
              });
              if (inda instanceof Array) {
                if (!inda.length) {
                  empString.includes(title) ? '' : empString.push(title);
                }
              } else {
                if (!inda) {
                  empString.includes(title) ? '' : empString.push(title);
                }
              }
            }

          }
        });
      }
      return empString.toString();
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
}
</style>