<template>
  <div class="mian"
    :style="{'width':width}">
    <!-- title -->
    <div class="title">
      {{title}}
    </div>
    <!-- 选择 -->
    <div :class="['select',selectClose?'select-close':'']">
      <!-- left select -->
      <el-select filterable
        :disabled="disabled"
        clearable
        style="margin-bottom: 16px;"
        @change="change"
        placeholder="请选择"
        v-model="selectData">
        <el-option v-for="item in typeList"
          :key="item[defaultProps.dictKey]"
          :label="item[defaultProps.dictValue]"
          :value="item[defaultProps.dictKey]"></el-option>
      </el-select>
      <!-- right slot -->
      <slot name="selectright"></slot>
    </div>
    <slot v-if="redata"></slot>
  </div>
</template>

<script>
export default {
  name: "ConfigurationSelection",
  model: {
    prop: 'redata',
    event: 'change'
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    selectClose: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '80%'
    },
    prop: {
      type: String,
      default: ''
    },
    typeList: {
      type: Array,
      default: () => []
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          dictKey: 'dictKey',
          dictValue: 'dictValue'
        };
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    redata: ''
  },
  watch: {
    redata: {
      deep: true,
      immediate: true,
      handler(n) {
        this.selectData = n;
      }
    }
  },
  data() {
    return {
      selectData: ''
    };
  },
  methods: {
    change(e) {
      let res = this.typeList.find(item => {
        return item[this.defaultProps.dictKey] == e;
      }) || '';
      this.$emit('change', res[this.defaultProps.dictKey] || '');
      this.$emit('select', res);
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-select {
  max-width: 450px !important;
}
.mian {
  display: column;
  margin-top: 24px;
  .title {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }
  .select {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .select-close {
    justify-content: flex-start;
  }
}
</style>
<style>
.el-select-dropdown {
  max-width: 80% !important;
}
</style>