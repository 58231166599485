<template>
  <el-checkbox-group v-model="checkList"
    @change="groupChange">
    <div v-for=" (de,di) in list"
      :key="di">
      <el-checkbox :label="de.key"
        :disabled="checkboxdisabled"
        @change="checkboxChange($event,de)">{{de.text}}</el-checkbox>
      <el-input v-model="de.value"
        :disabled="!checkList.includes(de.key)||disabled"
        type="textarea"
        :rows="5"
        placeholder="请输入内容">
      </el-input>
    </div>
  </el-checkbox-group>
</template>

<script>
export default {
  name: 'CheckboxGroupText',
  model: {
    prop: 'groupList',
    event: 'change'
  },
  props: {
    groupList: '',
    isSingle: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    checkboxdisabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      checkList: []
    };
  },
  watch: {
    groupList: {
      immediate: true,
      deep: true,
      handler(n) {
        this.checkList = n;
      }
    }
  },
  methods: {
    groupChange() {
      this.$emit('select', this.checkList);
    },
    checkboxChange(e, item) {
      if (this.isSingle) {
        if (e) {
          this.checkList = [item.key];
        } else {
          this.checkList = [];
        }
      }
      this.$emit('change', this.checkList);
    }
  }
}
</script>

<style lang="scss" scoped>
</style>