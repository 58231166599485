<template>
  <el-upload
    :disabled="disabled"
    class="avatar-uploader"
    action="#"
    :http-request="() => {}"
    :show-file-list="false"
    :on-change="uploadChange"
  >
    <el-image
      class="default-pic"
      v-if="imageUrl && type === 'BXTK'"
      fit="contain"
      :src="require(`@/assets/images/docTypesImg/` + fileType + `.png`)"
    />
    <el-image v-else-if="imageUrl" :src="imageUrl" />
    <el-image
      class="default-pic"
      v-else
      fit="contain"
      :src="require('@/assets/images/uploadDefPic.png')"
    />
    <div class="tips" v-if="type === 'BXTK'">
      格式仅支持doc、docx、pdf、xls、xlsx
    </div>
  </el-upload>
</template>

<script>
import { OSSDirectPass } from "@/api/oss.js";
export default {
  name: "ImageUpload",
  model: {
    prop: "imgList",
    event: "change",
  },
  props: {
    imgList: {
      type: [String, Array, Object],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      imageUrl: "",
      fileType: "",
    };
  },
  watch: {
    imgList: {
      immediate: true,
      deep: true,
      handler(n) {
        this.imageUrl = n.attachmentUrl;
        this.fileType = n.fileType;
      },
    },
  },
  methods: {
    async uploadChange(file) {
      let filenameSp = file.name.split(".");
      let attachmentUrl = await OSSDirectPass(file);
      this.fileType = filenameSp[filenameSp.length - 1];
      if (this.type === "BXTK") {
        if (
          !(
            this.fileType === "doc" ||
            this.fileType === "docx" ||
            this.fileType === "pdf" ||
            this.fileType === "xls" ||
            this.fileType === "xlsx"
          )
        ) {
          return this.$message.error("格式错误");
        }
      }
      this.imageUrl = attachmentUrl;
      let res = {
        orderType: 10,
        attachmentType: this.type,
        attachmentUrl,
        fileName: file.name,
        fileType: filenameSp[filenameSp.length - 1],
      };

      this.$emit("change", res);
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar-uploader {
  width: 132px;
  height: 132px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px dashed #cccccc;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .tips {
    position: absolute;
    left: 0;
    bottom: -20px;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    color: #999;
  }
  .default-pic {
    width: 72px;
    height: 72px;
  }
}
</style>
